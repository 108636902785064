import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BodyColor,TopTabColor }  from '../../BaseUrl';
import { AuthContext } from '../../components/context'; 
import { FaArrowLeft, FaBars, FaHome, FaLock, FaMoneyBill, FaUser,FaWallet,FaTasks,FaRegUserCircle,FaUsers } from "react-icons/fa";  
import '../../App.css'; 
import { BaseAction, imageURL } from '../../BaseUrl'; 
import Loading3 from '../loaders/Loading3'; 
import Loading4 from '../loaders/Loading4';
import Loading from '../loaders/Loading';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';



export default function StudentSubject() {
    const{  reloadUserData,signOut } = React.useContext(AuthContext);
    const navigate = useNavigate();    
    const[activateLoader, setActivateLoader]   = useState(false); 
    const[isLoading2, setIsLoading2]   = useState(false);  
    const[loginData, setLoginData] = useState([]);  
    const[message, setMessage] = useState('');  
    const[studentSubjectList, setStudentSubjectList] = useState([]);  
    const[studenCode, setStudenCode] = useState('');  
    const[studentID, setStudentID] = useState('');  
    const[studentTeacher, setStudentTeacher] = useState('');  
    const[studentClass, setStudentClass] = useState('');  
    const[studentName, setStudentName] = useState('');  
    const[studentPhoto, setStudentPhoto] = useState('');  
 
 
 const search = useLocation().search;


 const LoginOptionUpdate =() => {
     
 

    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 

   
  };


  useLayoutEffect(() => {
 

    LoginOptionUpdate();
 
           
  },[]); 




 const FetchStudentSubjects =(online_stu_id,school_code) => {
 
  setActivateLoader(true);
  setIsLoading2(true);

  var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
  fetch(BaseAction+"registeredSubjects&online_stu_id="+online_stu_id+"&school_code="+school_code,
      {
      method:'GET',
      header:headers
      })
            
            .then((response) => response.json())
            .then((responseJson) =>
            {  

               
                  localStorage.setItem('studentSubjectData',JSON.stringify(responseJson)); 
                  localStorage.setItem('onlineStuIdData',online_stu_id); 
                  setStudentSubjectList(responseJson);
                  setActivateLoader(false);
                  setIsLoading2(false);

          })
          .catch((error) => { 

                  setActivateLoader(true);
                  setIsLoading2(false);
                  setMessage('Network error. Please check your internet data and try again')

          });
 
   
  };




  useLayoutEffect(() => {
    

    const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
    const student_teacher= new URLSearchParams(search).get('student_teacher'); 
    const school_code    = new URLSearchParams(search).get('school_code'); 
    const student_class  = new URLSearchParams(search).get('student_class'); 
    const student_name   = new URLSearchParams(search).get('student_name'); 
    const photo          = new URLSearchParams(search).get('photo'); 
    
    setStudentTeacher(student_teacher);
    setStudenCode(school_code);
    setStudentID(online_stu_id);
    setStudentClass(student_class);
    setStudentName(student_name);
    setStudentPhoto(photo);



    const DataOnlineStuId  = localStorage.getItem('onlineStuIdData'); 
   
    const stData  = localStorage.getItem('studentSubjectData');  
    const data = JSON.parse(stData);


    if(data == null || DataOnlineStuId !== online_stu_id ){
  

      FetchStudentSubjects(online_stu_id,school_code);
  
      }else{
   
        setStudentSubjectList(data);
         
      }
    
    },[]);



 
 


const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }

if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Subjects"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{ 
    return (
        
        <div  style={{textAlign:"center",padding: 20,}} >

        <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code}}> 
        <Container>  
        <Row>
              <Col xs={5}>
              <img src={imageURL+loginData.school_code+'/'+studentPhoto} style={{marginTop:10,  }} className="imgLogo2"/>
              </Col>

              <Col xs={7} style={{textAlign:'left'}}> 
              <p  className="Bannerheader" style={{color:loginData.text_code,textTransform:'uppercase'}}> {studentName}</p>    
              <p   className="Bannerheader2" style={{marginTop:-15,  textTransform:'uppercase'}}> 
                  Student Teacher: {studentTeacher} <br/>
                  Student ID:      {studentID} <br/>
                  Student Class:   {studentClass}<br/>
                  School Code:     {studenCode}
                  </p> 
              </Col>
          </Row>
        </Container>

      </div>
      <div  style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}> 
      <span onClick={() => navigate('/')}> <FaArrowLeft   size={25} style={{color:  'black',marginRight:20}}/> </span>
       STUDENT REGISTERED SUBJECTS
       </div>

      {
        studentSubjectList !== null  || studentSubjectList !== ''?
                  studentSubjectList.map((item) => (
                  <div key={item.id}>
                    <Card  style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                      <Row>            
                          <Col  xs={12}>
                          <div style={{marginTop:30}}>
                          <p className='TextHeader3 '> {item.sub_1} </p> 
                          <p className='TextHeader3'> {item.sub_2} </p> 
                          <p className='TextHeader3'> {item.sub_3} </p> 
                          <p className='TextHeader3'> {item.sub_4} </p> 
                          <p className='TextHeader3'> {item.sub_5} </p> 
                          <p className='TextHeader3'> {item.sub_6} </p> 
                          <p className='TextHeader3'> {item.sub_7} </p> 
                          <p className='TextHeader3'> {item.sub_8} </p> 
                          <p className='TextHeader3'> {item.sub_9} </p> 
                          <p className='TextHeader3'> {item.sub_10} </p> 
                          <p className='TextHeader3'> {item.sub_11} </p> 
                          <p className='TextHeader3'> {item.sub_12} </p> 
                          <p className='TextHeader3'> {item.sub_13} </p> 
                          <p className='TextHeader3'> {item.sub_14} </p> 
                          <p className='TextHeader3'> {item.sub_15} </p> 
                          <p className='TextHeader3'> {item.sub_16} </p> 
                          <p className='TextHeader3'> {item.sub_17} </p> 
                          <p className='TextHeader3'> {item.sub_18} </p> 
                          <p className='TextHeader3'> {item.sub_19} </p> 
                          <p className='TextHeader3'> {item.sub_20} </p>  
                          <p className='TextHeader3'> {item.sub_21} </p>  
                          <p className='TextHeader3'> {item.sub_22} </p>  
                          <p className='TextHeader3'> {item.sub_23} </p>  
                          <p className='TextHeader3'> {item.sub_24} </p>  
                          <p className='TextHeader3'> {item.sub_25} </p>  
                          <p className='TextHeader3'> {item.sub_26} </p>  
                          <p className='TextHeader3'> {item.sub_27} </p>  
                          <p className='TextHeader3'> {item.sub_28} </p>  
                          <p className='TextHeader3'> {item.sub_29} </p>  
                          <p className='TextHeader3'> {item.sub_30} </p>  
                          <p className='TextHeader3'> {item.sub_31} </p>  
                          <p className='TextHeader3'> {item.sub_32} </p>  
                          <p className='TextHeader3'> {item.sub_33} </p>  
                          <p className='TextHeader3'> {item.sub_34} </p>  
                          <p className='TextHeader3'> {item.sub_35} </p>  
                          <p className='TextHeader3'> {item.sub_36} </p>  
                          <p className='TextHeader3'> {item.sub_37} </p>  
                          <p className='TextHeader3'> {item.sub_38} </p>  
                          <p className='TextHeader3'> {item.sub_39} </p>   
                          <p className='TextHeader3'> {item.sub_40} </p>   
                          <p className='TextHeader3'> {item.sub_41} </p>   
                          <p className='TextHeader3'> {item.sub_42} </p>   
                          <p className='TextHeader3'> {item.sub_43} </p>   
                          <p className='TextHeader3'> {item.sub_44} </p>   
                          <p className='TextHeader3'> {item.sub_45} </p>   
                          <p className='TextHeader3'> {item.sub_46} </p>   
                          <p className='TextHeader3'> {item.sub_47} </p>   
                          <p className='TextHeader3'> {item.sub_48} </p>   
                          <p className='TextHeader3'> {item.sub_49} </p>   
                          <p className='TextHeader3'> {item.sub_50} </p>   
 
                          </div>

                          </Col>


                      


                        </Row>
                    </Card> 
                </div>
                ))
            :
                  <Card>
                      <Row>
                          <Col>
                                <p> No Data Fetch</p><br/>

                                <Button    onClick={() => navigate('./')} style={{  fontSize:14,marginBottom: 2, }}>Back to home </Button> 
                          </Col>
                      </Row>
                  </Card>
      }

       

        </div>
        );
}






}



 