import { NavLink, useNavigate} from "react-router-dom";  
import React, { useState, useEffect, useLayoutEffect}  from 'react';
import { FaBars, FaHome, FaLock, FaBlog, FaUser,FaWallet,FaTasks,FaRegUserCircle,FaUsers } from "react-icons/fa";
import { MdMessage,MdOutlineCardMembership } from "react-icons/md"; 
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { VscReferences } from "react-icons/vsc";
import { SiCodeforces } from "react-icons/si";
import { TiGroup } from "react-icons/ti";
import { BiCog } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineLogout, AiFillCreditCard,  } from "react-icons/ai";
import { GiWallet,GiReceiveMoney } from "react-icons/gi";
import { BsCartCheck } from "react-icons/bs"; 
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { AuthContext } from '../../components/context';
import { TopColor,BgColor,textCode } from '../../BaseUrl';
import './Sidebar.css'

 



const routes = [
  {
    id: '1',
    path: "/",
    name: "Home",
    icon: <FaHome />,
  },
  {
    id: '2',
    path: "/school-blog",
    name: "Newsletter ",
    icon: <FaBlog />,
  },



  // {
  //   id: '4',
  //   path: "/transfer",
  //   name: "Transfer",
  //   icon: <VscReferences />,
  // },


  // {
  //   id: '6',
  //   path: "/my-invitee",
  //   name: "Customer Invited",
  //   icon: <FaUsers />,
  // },
  // {
  //   id: '7',
  //   path: "/virtual-card",
  //   name: "My Virtual Card",
  //   icon: <AiFillCreditCard />,
  // },
  // {
  //   id: '8',
  //   path: "/cashout-history",
  //   name: "Transaction History",
  //   icon: <AiFillCreditCard />,
  // },
  // {
  //   id: '5',
  //   path: "/file-manager",
  //   name: "Loan Request",
  //     icon: <GiWallet />,
  //     subRoutes: [
  //       {   id: '10',
  //         path: "/Settings/profile",
  //         name: "Cash Loan ",
  //         icon: <GiReceiveMoney />,
  //       },
  //       {
  //         id: '11',
  //         path: "/Settings/2fa",
  //         name: "Commodity Loan",
  //         icon: <FaWallet />,
  //       },
  //       {

  //         id: '12',
  //         path: "/Settings/billing",
  //         name: "Loan History",
  //         icon: <FaHistory />,
  //       },
  //     ],
  // },
  {
    id: '9',
    path: "/Settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
 
      {
        id: '5',
        path: "/settings/password-reset",
        name: "Password reset",
        icon: <AiFillCreditCard />,
      },
      {
        id: '6',
        path: "/profile",
        name: "Profile",
        icon: <AiFillCreditCard />,
      },
 
    ],
  },

  {
    id: '12',
    path: "/Logout",
    name: "Logout",
    icon: <AiOutlineLogout    />,
  },

];

const SideBar = ({children ,props}) => {


const [width1, setWidth1] = useState(''); 
const [width2, setWidth2] = useState('');  
const [textCode, setTextCode] = useState('');
const [bgColor, setBgColor] = useState('');
const [show, setShow] = useState(false);
const [isOpen, setIsOpen] = useState(false);//false default 
const[loginData, setLoginData] = useState([]);  
const [windowDimenion, detectHW] = useState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
})

  const { signOut } = React.useContext(AuthContext);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
 
  useEffect(() => {
     
    const value =  localStorage.getItem('login_session');
    const data  = JSON.parse(value); 
    setLoginData(data);
    SreenAdjust()

  },[]);


  useLayoutEffect(() => {
     
    const school_bgcolor = localStorage.getItem('school_bgcolor');
    const text_code      = localStorage.getItem('text_code');
    setBgColor(school_bgcolor);
    setTextCode(text_code);

  },[]);

 
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }


  }, [windowDimenion])


const SreenAdjust = () => {
 
  if(windowDimenion.winWidth > 768){
    //Desktop settings
    setIsOpen(true);
    setWidth1('25%');
    setWidth2('50px');

  }else{
    //Mobile settings
    setIsOpen(false);
    setWidth1('70%');
    setWidth2('0');

    
  }


}
 

const LogoutHandler = () => {
   
                  
  navigate('./profile');

}
const MouseOverHanher = () => {
  
  if(windowDimenion.winWidth > 768){
    //Desktop settings 

  }else{
    //Mobile settings 
    setIsOpen(false);
    
  }


}
//console.log(width1 , width2)


  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
 


 

if(show){

  navigate('/Login');

}
else
{

    return (    
   
    <div>
            <nav className='mainNav' style={{backgroundColor:bgColor}}>
            


              <div  className='navTab1' >
              <FaBars onClick={toggle}  size={25} style={{color:  textCode}}/><br/>
              <small style={{fontSize:12,color:'#777777'}}>Menu</small>
              </div>


       
               <div className='navTab2'></div>

              
              <div className='navTab3'  onClick={LogoutHandler}  style={{color: textCode}}>
              <FaRegUserCircle onClick={toggle}   style={{color:  textCode}}/><br/>
              <div style={{fontSize:12,color:textCode,textAlign: 'right'}}>{loginData.guidance_name}</div>
              </div>


            
            </nav>


            <div className="main-container" >
        
                <motion.div
                style={{backgroundColor: 'rgb(200, 204, 206',color:bgColor}}

                    animate={{
                      width: isOpen ? width1 : width2,

                      transition: {
                        duration: 0.5,
                        type: "spring",
                        damping: 10,
                      },
                    }}
                    className={`sidebar`}
                   
                >

 
                          <div className="top_section" style={{ width: isOpen ? width1 : width2,}}>
                           
                            <AnimatePresence>
                            {isOpen && (
                              <motion.h1
                                variants={showAnimation}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                                className="logo"
                              >
                                
                              
                              </motion.h1>
                            )}
                          </AnimatePresence>
                          </div>

                
                         <section className="routes">

                         
                      
                        {
                          routes.map((route) => {
                          
                          if (route.subRoutes) {
                            return (
                              <SidebarMenu
                              key={route.id}
                                setIsOpen={setIsOpen}
                                route={route}
                                showAnimation={showAnimation}
                                isOpen={isOpen}
                              />
                            );
                          }

                          return (
                            <NavLink
                              to={route.path}
                              key={route.id}
                              className="link"
                            
                            >
                              <div className="icon">{route.icon}</div>
                              <AnimatePresence>
                                {isOpen && (
                                  <motion.div
                                    variants={showAnimation}
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                    className="link_text"
                                  >
                                    {route.name}
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </NavLink>

                            
                          );

                  

                        })
                        
                        }

                        



                        </section>





              

              </motion.div>

              <main onMouseOver={MouseOverHanher} className="mainChildren"  style={{width: '100%'}}>{children}</main>  
      
            </div>              

    </div>

  );


}
   

 



            

}
export default SideBar;
