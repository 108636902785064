import React, { useState, useEffect, useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'
  
 import { AuthContext } from '../../../components/context'; 
import '../Dashboard.css'; 
import '../../../App.css'; 
import { BaseAction, imageURL } from '../../../BaseUrl'; 
import Loading3 from '../../loaders/Loading3'; 
import Loading4 from '../../loaders/Loading4';
import Loading from '../../loaders/Loading';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
 



 

const ReturnToPage = (props) => {
  const [message, setMessage] = useState('');   
  const navigate = useNavigate();    

  const { endCBT } = React.useContext(AuthContext);
 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  //////////////////////////////////////////////////////////////
  const [registeredSubjects, setRegisteredSubjects] = useState(false);  
  //////////////////////////////////////////////////////////////
  const[loginData, setLoginData] = useState([]);  
  //////////////////////////////////////////////////////////////
  const [studentOnList, setStudentOnList] = useState([]);  
  const [textCode, setTextCode] = useState('');
  const [bgColor, setBgColor] = useState('');


 
 
  const LoginOptionUpdate =() => {
     
 

      const value =  localStorage.getItem('login_session');
      const data = JSON.parse(value); 
      setLoginData(data); 

     
    };

    useEffect(() => {


      const school_bgcolor = localStorage.getItem('school_bgcolor');
      const text_code      = localStorage.getItem('text_code');
      setBgColor(school_bgcolor);
      setTextCode(text_code);

      LoginOptionUpdate();

       //  if(loginData.school_bgcolor == null){
      //   signOut();
      //  }else{
      //     LoginOptionUpdate();
      //  }
             
    },[]); 

 
 
   
 useLayoutEffect(() => {
    
  const stData  = localStorage.getItem('studentOnListData');  
  const data = JSON.parse(stData);
 
 
      setStudentOnList(data); 
    
  
  },[]);
        

  

 
  
  
  const  SubEndPitcherDown =() =>{
  /// double button modal OFF
  setRegisteredSubjects(false);
  }   


  const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }

 
          return (
        
            <div  style={{textAlign:"center",padding: 20,}} >

          <div     style={{backgroundColor:bgColor,color:textCode,width:'100%',paddingTop:20,paddingBottom:10}}> 
            <Container>  
            <Row>
                  <Col xs={3}>
                  <img src={imageURL+props.school_code+'/'+props.schoolLogo} style={{marginTop:10,  }} className="imgLogo"/>
                  </Col>

                  <Col xs={9} style={{textAlign:'left'}}> 
                      <p  className="Bannerheader"style={{fontWeight: 'bold',  color:textCode,textTransform:'uppercase'}}> {props.schoolName}</p>    
                      <p  className="Bannerheader2"style={{color:textCode, marginTop:-15, }}> 
                      Address: {props.schoolAddress} <br/>
                      Phone:   {props.schoolPhone} <br/>
                      Email:   {props.schoolEmail}<br/>
                      Mottor:{props.schoolMotor}
                      </p> 
                  </Col>
              </Row>
            </Container>

          </div>
          <div style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}> BACK TO CBT </div>

         
                        <Card  className="BoxShaddow"  style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                          <Row>            
                              <Col  xs={3}>
                              <div style={{marginTop:30}}>
                                <p style={{fontWeight: 400,color:loginData.school_bgcolor,marginBottom: 5,fontSize:15,textAlign:'center',textTransform:'capitalize' }}>{loginData.sub_status} </p> 
                                <img src={imageURL+props.school_code+'/'+loginData.photo} style={{marginBottom:20,width: 100,height: 100,borderRadius:800}} /><br/>
                             
                              </div> 
                             </Col>

                  {loginData.sub_status == 'active'?
                      <Col xs={9} style={{textAlign:'left',paddingLeft:30}}>
                      <p style={{fontWeight: 'bold',color:'black',marginBottom: 5,fontSize:16,textTransform:'uppercase'}}>{loginData.student_name}</p>    
                      <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./general-cbt?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take CBT Training</Button>   
                      <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./mid-term-test?online_stu_id='+    loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take Mid-Term Test</Button>   
                      <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}} onClick={() => navigate('./school-exam?online_stu_id='+loginData.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+loginData.student_teacher+'&student_name='+loginData.student_name+'&photo='+loginData.photo+'&student_class='+loginData.student_class)}>Take School CBT Exam</Button>   
            
                      
                      </Col>
                      :
                      <Col xs={9} style={{textAlign:'left',paddingLeft:30}}>
                      <p style={{fontWeight: 'bold',color:'black',marginBottom: 5,fontSize:16,textTransform:'uppercase'}}>{loginData.student_name}</p>         
                      <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Take CBT Training</Button>   
                      <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Take Mid-Term Test</Button>   
                      <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%'}}>Take School CBT Exam </Button>   
            
                      
                      </Col>           


                  }

                      <Col xs={3}></Col>
                          <Col xs={6}>   
                          <div   onClick={() => endCBT()} style={{backgroundColor:'#777777',color:'white',fontSize:16,borderRadius:8,padding:5,marginLeft:5}}>
                          END CBT 
                          </div> 
                          </Col>
                      <Col xs={3}></Col>


                            </Row>
                        </Card> 

                   
           

            </div>
            );
                
 

        
};

export default ReturnToPage; 
 

  