import React, { useState, useEffect, useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'
import { MdTipsAndUpdates,MdVerified,MdCancelPresentation } from "react-icons/md";
// import { BsShieldFillCheck } from "react-icons/bs";
// import { GiMoneyStack } from "react-icons/gi";
// import { GiAlliedStar} from "react-icons/gi";    
//  import { MdOutlineGppGood, MdGppGood,MdGroups} from "react-icons/md";  
// import {  FaHourglassHalf,FaHourglassEnd,FaExternalLinkAlt,FaUsers,FaUserTimes } from "react-icons/fa";  
// import { AiOutlineReload} from "react-icons/ai"; 
import {GiPadlock,GiPadlockOpen,GiCheckMark} from "react-icons/gi";  
import Modal from "../UI/Modal/Modal";  
//  
 import { AuthContext } from '../../components/context'; 
import './Dashboard.css'; 
import '../../App.css'; 
import { BaseAction, imageURL, ResultDownload } from '../../BaseUrl';  
import Loading4 from '../loaders/Loading4'; 
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row} from 'react-bootstrap';
 



 

const Dashboard = (props) => {
  const [message, setMessage] = useState('');   
  const navigate = useNavigate();    

  const {  reloadUserData,signOut } = React.useContext(AuthContext);
 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  //////////////////////////////////////////////////////////////
  const [registeredSubjects, setRegisteredSubjects] = useState(false);  
  //////////////////////////////////////////////////////////////
  const[loginData, setLoginData] = useState([]);  
  //////////////////////////////////////////////////////////////
  const [studentOnList, setStudentOnList] = useState([]);  
  const [textCode, setTextCode] = useState('');
  const [bgColor, setBgColor] = useState('');


 
 
  const LoginOptionUpdate =() => {
     
 

      const value =  localStorage.getItem('login_session');
      const data = JSON.parse(value); 
      setLoginData(data); 

     
    };

    useEffect(() => {


      const school_bgcolor = localStorage.getItem('school_bgcolor');
      const text_code      = localStorage.getItem('text_code');
      setBgColor(school_bgcolor);
      setTextCode(text_code);

      LoginOptionUpdate();

       //  if(loginData.school_bgcolor == null){
      //   signOut();
      //  }else{
      //     LoginOptionUpdate();
      //  }
             
    },[]); 

 
 
   
 useLayoutEffect(() => {
    
  const stData  = localStorage.getItem('studentOnListData');  
  const data = JSON.parse(stData);
    if(data == null){

      setActivateLoader(true);
      setIsLoading2(true);
      FetchEnrolledStudents();

    }else{
 
      setStudentOnList(data);
      //console.log(data);
    }
  
  },[]);
        

  

  const FetchEnrolledStudents =() => {

    const parent_code =  localStorage.getItem('parentCode');
    const sch_code    =  localStorage.getItem('schoolCode');
    
     
    setActivateLoader(true);
    setIsLoading2(true);


    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"StudentOnList&parent="+parent_code+"&school_code="+sch_code,
        {
        method:'GET',
        header:headers
        })
              
              .then((response) => response.json())
              .then((responseJson) =>
              {  

                 
                    localStorage.setItem('studentOnListData',JSON.stringify(responseJson)); 
                    setStudentOnList(responseJson);
                    setActivateLoader(false);
                    setIsLoading2(false);

            })
            .catch((error) => { 

                    setActivateLoader(true);
                    setIsLoading2(false);
                    setMessage('Network error. Please check your internet data and try again')

            });
  }
 
 
  const FetchStudentSubjects = (stu_id, schl_code, stu_class, stu_photo,stu_name) =>{

   alert(stu_id+schl_code+stu_class+stu_photo+stu_name)

  }

  
  
  const  SubEndPitcherDown =() =>{
  /// double button modal OFF
  setRegisteredSubjects(false);
  }   


  const  ReLoadHanduler =() =>{
    setActivateLoader(false);  
    setIsLoading2(false);
    
    
  }   
    
 const refreshPage = () => {
    // setTimeout(()=>{
        window.location.reload();
    //      setShow(true);
    // }, 5000);
     
  }

if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                  <div> 
                  <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading Student"} />
                  </div>
                )
                
          }           
          else  
          {      
        
                  return( 
                  <div> 
                  <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                  </div> 
                  )       
           
          }
              
  
  
}
else
{

          return (
        
            <div  style={{textAlign:"center",padding: 20,}} >

          <div     style={{backgroundColor:bgColor,color:textCode,width:'100%',paddingTop:20,paddingBottom:10}}> 
            <Container>  
            <Row>
                  <Col xs={3}>
                  <img src={imageURL+loginData.school_code+'/'+loginData.school_logo} style={{marginTop:10,  }} className="imgLogo"/>
                  </Col>

                  <Col xs={9} style={{textAlign:'left'}}> 
                      <p  className="Bannerheader"style={{fontWeight: 'bold',  color:textCode,textTransform:'uppercase'}}> {props.schoolName}</p>    
                      <p  className="Bannerheader2"style={{color:textCode, marginTop:-15, }}> 
                      Address: {props.schoolAddress} <br/>
                      Phone:   {props.schoolPhone} <br/>
                      Email:   {props.schoolEmail}<br/>
                      Mottor:  {props.schoolMotor}
                      </p> 
                  </Col>
              </Row>
            </Container>

          </div>
          <div style={{fontSize:20,color:'black',fontWeight: 'bold',textDecorationLine: "underline",marginTop:20}}>ENROLLED STUDENTS </div>

          {
            studentOnList !== null?
                        studentOnList.map((item) => (
                        <Card  className="BoxShaddow"  key={item.id} style={{marginTop:10,marginBottom:10,paddingBottom:10}} > 
                          <Row>            
                              <Col  xs={3}>
                              <div style={{marginTop:30}}  >
                                <p style={{fontWeight: 400,color:loginData.school_bgcolor,marginBottom: 5,fontSize:15,textAlign:'center',textTransform:'capitalize' }}>{item.sub_status} </p> 
                                <img src={imageURL+loginData.school_code+'/'+item.photo} style={{marginBottom:20,width: 100,height: 100,borderRadius:800}} /><br/>
                                
                                <a   href={ResultDownload+'student_result_download.php?student_id='+item.online_stu_id+'&token='+loginData.token+'&parent_id='+loginData.parent_code+'&school_code='+loginData.school_code}>
                                 <Button style={{backgroundColor:bgColor,color:textCode,fontSize:9,marginBottom: 2,borderColor:textCode,width:'100%'}}  > Student e-Report </Button> 
                               </a>

                               <a   href={ResultDownload+'school_payment_link.php?school_code='+loginData.school_code+''}>
                                 <Button style={{backgroundColor:'#c7d2f8',color:'black',fontSize:9,marginBottom: 2,borderColor:textCode,width:'100%',marginTop:5,marginLeft:5}}  > Online Payment  </Button> 
                               </a>

                                <a   href={ResultDownload+'student_payment_clearance.php?student_id='+item.online_stu_id+'&token='+loginData.token+'&parent_id='+loginData.parent_code+'&school_code='+loginData.school_code}>
                                 <Button style={{backgroundColor:'#c7d2f8',color:'black',fontSize:9,marginBottom: 2,borderColor:textCode,width:'100%',marginLeft:5}}  > Student clearance </Button> 
                               </a>
                              </div>

                              </Col>

                  {item.sub_status == 'active'?
                  <Col xs={9} style={{textAlign:'left',paddingLeft:30}}>
                  <p style={{fontWeight: 'bold',color:'black',marginBottom: 5,fontSize:16,textTransform:'uppercase'}}>{item.student_name}</p>
                  <Button  style={{backgroundColor:'#f2f2f2',color:'black',fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'uppercase',width:'95%',textAlign:'left'}}>Class: {item.student_class} </Button>      
                  <Button  style={{backgroundColor:'#f2f2f2',color:'black',fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'uppercase',width:'95%',textAlign:'left'}}>Student ID : {item.online_stu_id } </Button>     
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}}  onClick={() => navigate('./stu-subject?online_stu_id='+   item.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+item.student_teacher+'&student_name='+item.student_name+'&photo='+item.photo+'&student_class='+item.student_class)} >Registered Subjects</Button>   
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}} onClick={() => navigate('./scheme-of-work?online_stu_id='+    item.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+item.student_teacher+'&student_name='+item.student_name+'&photo='+item.photo+'&student_class='+item.student_class)}>scheme of work</Button> 
                  <Button  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}} onClick={() => navigate('./general-cbt?online_stu_id='+    item.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+item.student_teacher+'&student_name='+item.student_name+'&photo='+item.photo+'&student_class='+item.student_class)}>Take CBT Training</Button> 
                  <Button   onClick={() => navigate('./student-exam-result?online_stu_id='+item.online_stu_id +'&school_code='+loginData.school_code+'&student_teacher='+item.student_teacher+'&student_name='+item.student_name+'&photo='+item.photo+'&student_class='+item.student_class)}  style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}}>Exam/Test/Weekly Assessment Scores</Button>   
         
                  
                  </Col>
                  :
                  <Col xs={9} style={{textAlign:'left',paddingLeft:30}}>
                  <p style={{fontWeight: 'bold',color:'black',marginBottom: 5,fontSize:16,textTransform:'uppercase'}}>{item.student_name}</p>
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'uppercase',width:'95%',textAlign:'left'}}>Class: {item.student_class} </Button>      
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'uppercase',width:'95%',textAlign:'left'}}>Student ID : {item.online_stu_id } </Button>     
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}}>Registered Subjects</Button>   
                  <Button  style={{backgroundColor:'#777777',color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode,textTransform:'capitalize',width:'95%',textAlign:'left'}}>Take CBT Training</Button>   
        
                  
                  </Col>           


                  }



                            </Row>
                        </Card> 

                    ))
                :
                      <Card>
                          <Row>
                              <Col>
                                    <p> No Data Fetch</p><br/>

                                    <Button   onClick={() => FetchEnrolledStudents()} style={{backgroundColor:bgColor,color:textCode,fontSize:14,marginBottom: 2,borderColor:textCode}}>Reload Student Data </Button> 
                              </Col>
                          </Row>
                      </Card>
          }

           

            </div>
            );
                
 

        } 
};

export default Dashboard; 
 

  