import React, { useState,useCallback, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BaseAction, imageURL,questionImg } from '../../../BaseUrl'; 
import Loading4 from '../../loaders/Loading4';
import { AuthContext } from '../../../components/context'; 
import { FaArrowLeft,  } from "react-icons/fa";  
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import './index.css';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row ,Modal} from 'react-bootstrap';





function SchemeOfWorkPage({questions, ReLoadHanduler,curStuID,  }) {
  const { startCBT,endCBT } = React.useContext(AuthContext);
 const [currentQuestion, setCurrentQuestion] = useState(0);
 const[activateLoader, setActivateLoader]   = useState(false); 
 const[isLoading2, setIsLoading2]   = useState(false); 
 const[message, setMessage] = useState('');  
 const[loginData, setLoginData] = useState([]); 
 const{ teacher_photo,school_week,  } = questions[currentQuestion];
     
 const[pickedSubject, setPickedSubject] = useState('');
 //////////////////////////////////////////////////////////
 const[schoolCode, setSchoolCode] = useState('');  
 const[studentID, setStudentID] = useState('');  
 const[studentClass, setStudentClass] = useState('');  
 const[studentName, setStudentName] = useState('');  
 const[studentPhoto, setStudentPhoto] = useState('');  
 const[studentTeacher, setStudentTeacher] = useState(''); 
 ////////////////////////////////////////////////////////////
                 
 const handle = useFullScreenHandle();

 
 ///////////////////////////////////////////////////////////
const [currentAnswerPicked, setCurrentAnswerPicked] = useState({}); 
const [savedPicked, setSavedPicked] = useState('') 
 ////////////////////////////////////////////////////////////
 const [reUpdateScore, setReUpdateScore] = useState(false) 
 ////////////////////////////////////////////////////////////
 const navigate = useNavigate(); 

 
 
const search = useLocation().search;

  const LoginOptionUpdate =() => { 
    
    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 
 
  };

 
  useLayoutEffect(() => {
    

    const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
    const student_teacher= new URLSearchParams(search).get('student_teacher'); 
    const school_code    = new URLSearchParams(search).get('school_code'); 
    const student_class  = new URLSearchParams(search).get('student_class'); 
    const student_name   = new URLSearchParams(search).get('student_name'); 
    const photo          = new URLSearchParams(search).get('photo'); 
    
    setStudentTeacher(student_teacher);
    setSchoolCode(school_code);
    setStudentID(online_stu_id);
    setStudentClass(student_class);
    setStudentName(student_name);
    setStudentPhoto(photo);


    const stData  = localStorage.getItem('subject');  
    setPickedSubject(stData);

    LoginOptionUpdate();
 
           
  },[]); 

 
    const endCBTHanduler = () => {
            endCBT();
            navigate('/');
   
      }
 

 
 
        
        return (  
          <div  style={{backgroundColor:'white'}} >
       
            <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code,marginTop:5}}>  
                <div  className="colTextTwo" style={{width: '100%',color:loginData.text_code,textTransform:'uppercase',textAlign:'center',}} >
                SCHEME OF WORK PREPARED BY
                </div>  
            </div>         
                            
    
            <div className="quiz-container" style={{textAlign:'center',paddingBottom:20}}>

                            <div onClick={() => ReLoadHanduler()} style={{textAlign:'start'}}> 
                                <FaArrowLeft   size={25} style={{color:  'black',marginRight:20}}/> 
                            </div>


                            <img src={imageURL+"/"+loginData.school_code+"/"+teacher_photo} style={{marginBottom:5,width: 100,height: 100,borderRadius:800}} /> 
                            <div style={{fontWeight: 'Bold',fontSize:14, color:'black',textTransform:'uppercase'}}>
                            {studentTeacher} 
                            </div>
                           

                            <div style={{marginTop:20,padding:20}}>
                            <div style={{width: '100%',fontWeight: 'Bold',fontSize:14, color:'black', }} >Current School Week </div>
                            <div  style={{fontSize:30,fontWeight: 'Bold',}}> {school_week}</div>
                            </div> 
             </div>


            <div className="block">
            {
            /////////////////////////////////////////   THE BLOCK GOES HERE
            }
            </div>        

              <div style={{color:'black',fontSize:20,textTransform:'uppercase',textAlign:'center',marginTop:30}}> {curStuID} </div>
              <div style={{color:'black',fontSize:16,textTransform:'uppercase',textDecorationLine: "underline",textAlign:'center',marginTop:5}}>
              {studentClass } SCHEME OF WORK
              </div> 

              <div style={{flexDirection:'column',width: '95%',alignItems:'flex-start' }}> 


                          <div style={{marginTop:10,padding:5,width:'100%',marginBottom:10}}>
                          {               
                              questions.map((item) => (
                                <div   key={item.id} > 

                               <div style={{marginTop:10,padding:5,width:'100%',backgroundColor:'#eeeeee',textAlign:'center'}}>
                                  <p>Week: <p style={{fontSize:30,fontWeight:'bold'}}>  {item.week}  </p>   </p> 
                               </div>
                               
                                
                                <div style={{marginTop:0,padding:5,width:'100%',backgroundColor:'#c7d2f8',}}>
                                <p style={{fontSize:15,marginTop:10,fontWeight:'bold'}}>Topic:  </p>
                                <p style={{fontSize:15,}}> {item.topic}</p>
                                </div>
  
                                <div style={{ padding:5,width:'100%',backgroundColor:'#d7a3a3',}}> 
                                <p style={{fontSize:15,fontWeight:'bold'}}>Content:  </p> 
                                <p style={{color:'black'}}> {item.content}</p>
                                </div> 
                                 
  
                                </div>
                              )) 
                        }
                          </div>


              </div>





        </div>
        );
            
       
}
 
export default SchemeOfWorkPage
 