
export const switchUrl               = "https://adminportal.com.ng/login/"; 
//export const switchUrl             = "http://127.0.0.1/adminportal.com.ng/login/"; 
///////////////////////////////////////////////////////////////
export const MainUrl                 = switchUrl+"myschoolapp_api/school_api.php?action=" 
///////////////////////////////////////////////////////////////
export const MainimageURL            = switchUrl+"myschoolapp_api/school/" 
///////////////////////////////////////////////////////////////
export const MainquesImg            = switchUrl+"myschoolapp_api/question_img/" 
///////////////////////////////////////////////////////////////
export const ResultDownload         = switchUrl+"school-admin/"; 


export const Url                       = MainUrl;
export const imageURL                  = MainimageURL;
export const questionImg               = MainquesImg;
export const BaseAction                = Url;//NEW
export const fingerPrintloginUpdate    = Url;
export const TopTabColor               = "#0175be"; 
export const BasePageAction            = Url; 
export const Naira                     = "₦";
export const TopColor                  = "black";
export const BodyColor                 = "#0175be";
export const BgColor                   = "#eee";
export const TabBodyColor              = "#fff";


