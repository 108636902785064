import React, { useState,useCallback, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom'; 
import { BaseAction, imageURL,questionImg } from '../../../BaseUrl'; 
import Loading4 from '../../loaders/Loading4';
import { AuthContext } from '../../../components/context'; 
import {resultInitialState} from './Constant'; 
import md5               from 'md5-hash';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import './index.css';
import {FloatingLabel,Card, Table, Form,Button, Col,Container,Row ,Modal} from 'react-bootstrap';





function GeneralCBTPage({questions,FetchGeneralCBTQuestion,FetchGeneralCBTNextQuestion,CBTPage,SubjecdID,time,score}) {
  const { startCBT,endCBT } = React.useContext(AuthContext);
 const [currentQuestion, setCurrentQuestion] = useState(0);
 const[activateLoader, setActivateLoader]   = useState(false); 
 const[isLoading2, setIsLoading2]   = useState(false); 
 const[message, setMessage] = useState('');  
 const[loginData, setLoginData] = useState([]); 
 const{question, choices, correctAnswer,question_img } = questions[currentQuestion];
 const[answerIdx, setAnswerIdx] = useState(null);
 const[answer, setAnswer] = useState(null);
 const[result, setResult] = useState(resultInitialState);
 const[showResult, setShowResult] = useState(false);
 const[pickedSubject, setPickedSubject] = useState('');
 //////////////////////////////////////////////////////////
 const[schoolCode, setSchoolCode] = useState('');  
 const[studentID, setStudentID] = useState('');  
 const[studentClass, setStudentClass] = useState('');  
 const[studentName, setStudentName] = useState('');  
 const[studentPhoto, setStudentPhoto] = useState('');  
 const[studentTeacher, setStudentTeacher] = useState(''); 
 ////////////////////////////////////////////////////////////
 const [seconds, setSeconds] = useState(59);
 const [minutes, setMinutes] = useState(time);
 const [seconds2, setSeconds2] = useState(0);
 const [minutes2, setMinutes2] = useState(0);
 const [pickTime, setpickTime] = useState('');
 const handle = useFullScreenHandle();

 
 ///////////////////////////////////////////////////////////
const [currentAnswerPicked, setCurrentAnswerPicked] = useState({}); 
const [savedPicked, setSavedPicked] = useState('') 
 ////////////////////////////////////////////////////////////
 const [reUpdateScore, setReUpdateScore] = useState(false) 
 ////////////////////////////////////////////////////////////
 const navigate = useNavigate(); 


 useLayoutEffect(() => {
  startCBT()

 },[]);



 const onAnswerClick = (ans, index) =>{
  var curQues ='ques'+ (currentQuestion + 1); //QUESTION NO
  var indexPick ='indexPick'+(currentQuestion+1); //PICKED INDEX 
  var ansWer ='ans'+ (currentQuestion + 1); // PICKED ANSWER

  
 // Set index from the option selected
  setAnswerIdx(index)
  //set answer from the options selected
  setAnswer(ans)

   // Search the array Object and find the current question picked in text by ther user
  const curQuesResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
    return key.includes(curQues) || value.toString().includes(curQues);
    
   }))

 // Search the array Object and find the result picked in text by ther user
 const pickedAnsResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
  return key.includes(ansWer) //|| value.toString().includes(ansWer);
  
 }))

   if(curQuesResult[curQues] >= 0)
   {

          if(ans == null){
              
              //console.log('void repicked +0');  //checked
              setResult((prev) => ({
                ...prev, 
              }));
          }
          else
          {       /////  CORRECT ANSWER RE-PICKED BLOCK
                if(md5(ans) == correctAnswer)
                { 
                      ///  previous picked answer   Equal To    New right answer picked
                      //should incase user keep pressing the right answer to generate more score
                      if(md5(pickedAnsResult[ansWer]) == md5(ans))
                      { //Mark already added here
                        // console.log('Right-Right +0');  
                         setResult((prev) => ({
                          ...prev
                        })); 
                      }
                      else  if(md5(pickedAnsResult[ansWer]) != md5(ans))
                      { /////  previous picked answer   !=   New right answer picked

                             // console.log('Wrong-Wright +5'); 
                            setResult((prev) => ({
                              ...prev,
                              score:prev.score + Number(score),
                              correctAnswers:prev.correctAnswers + 1,  
                              wrongAnswers:prev.wrongAnswers - 1, 
                            }));                              
                      }
                      else
                      {
                       // console.log('Right-Right-Right +0');  
                        setResult((prev) => ({
                         ...prev,  
                       })); 

                      }

                }
                else
                {  /////  WRONG ANSWER RE-PICKED BLOCK
                                 
                      if(md5(pickedAnsResult[ansWer]) != correctAnswer)
                      {/////  previous picked answer   ==   system correct 

                         // console.log('Wrong->Wrong + 0'); //checked
                          setResult((prev) => ({
                            ...prev, 
                          }));  

                      }
                      else if(md5(pickedAnsResult[ansWer]) == correctAnswer)
                      {   /////  previous picked answer   ==   system correct 



                           // console.log('Right-> Wrong 0'); //checked
                            setResult((prev) => ({
                              ...prev,
                              score:prev.score - Number(score),
                              correctAnswers:prev.correctAnswers - 1,  
                              wrongAnswers:prev.wrongAnswers + 1, 
                            }));  
                            
                      } 

                } 
          }


   }
   else
   {
     



             if(md5(ans) === correctAnswer)
             {
                  //  console.log('First Right  +5'); //checked
                   setResult((prev) => ({
                     ...prev,
                     score:prev.score + Number(score),
                     correctAnswers:prev.correctAnswers + 1,  
                   }));
             }
             else
             {   
                  // console.log('First Wrong 0');  //

                   setResult((prev) => ({
                     ...prev,  
                     wrongAnswers:prev.wrongAnswers + 1, 
                   }));

             } 
   }




 //// ADD to object on option select
  setCurrentAnswerPicked((prevAns) => 
    ({...prevAns, [curQues]: currentQuestion + 1,  [ansWer]: ans, [indexPick]:index})
  )

 
  // console.log('ONCLICK-ques->'+curQues); 
  // console.log('MARK->'+result.score)
  // console.log('COR ANS->'+result.correctAnswers)
  // console.log('WOR ANS->'+result.wrongAnswers)
}

const onClickNext = () =>{
  
//answerIdx
//********************************************************************** */
var curQues   ='ques'+ (currentQuestion+1); //QUESTION NO
var indexPick ='indexPick'+(currentQuestion+2); //PICKED INDEX 
var ansWer    ='ans'+ (currentQuestion+1); // PICKED ANSWER

 
///   Search the array Object and find the question number
 const curQuesResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
   return key.includes(curQues) || value.toString().includes(curQues);
   
  }))

// Search the array Object and find the result picked index by ther user
 const pickedIndexResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
   return key.includes(indexPick) //|| value.toString().includes(indexPick2);
   
  }))

 // Search the array Object and find the result picked in text by ther user
  const pickedAnsResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
    return key.includes(ansWer) //|| value.toString().includes(ansWer);
    
   }))

   //********* */ update the index picked by user to be view by user *********
   setAnswerIdx(pickedIndexResult[indexPick]);





   // FOR DEBUG PURPOSE
  //  console.log(currentAnswerPicked);
  //  console.log('NEXT-ques->'+curQues);
  // console.log('MARK->'+result.score)
  // console.log('NEXT-question->'+curQuesResult[curQues]);
  //  console.log('NEXT-pik ans->'+pickedAnsResult[ansWer]);
  //  console.log('NEXT-pik indx->'+pickedIndexResult[indexPick]);
  

 

        if (currentQuestion !== questions.length - 1 ){

          setCurrentQuestion((prev) => prev + 1)

        }
        else
        {
          
            if(CBTPage == 'MidTermCBT' || CBTPage == 'Examination')
              {

              UpdateResultScore(); 
  

            }else if(CBTPage == 'GeneralCBT'){

            setCurrentQuestion(0);

            setShowResult(true);

            }

   
      }

}


const onClickPrevious = () =>{


setCurrentQuestion((prev) => prev - 1); 
 var curQues ='ques'+ (currentQuestion)
// var ansWer ='ans'+ (currentQuestion); 
var indexPick ='indexPick'+currentQuestion; //PICKED INDEX 

///   Search the array Object and find the question number *NOT IN USE HERE*
const curQuesResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
  return key.includes(curQues) || value.toString().includes(curQues);
  
 }))

// // Search the array Object and find the result picked index by ther user *NOT IN USE HERE*
// const pickedAnsResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
//   return key.includes(ansWer) //|| value.toString().includes(ansWer);
  
//  }))
  

  const pickedIndexResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
    return key.includes(indexPick) //|| value.toString().includes(indexPick);
    
   }))



    //********* */ update the index picked by user to be view by user *********
    setAnswerIdx(pickedIndexResult[indexPick]); 



    // FOR DEBUG PURPOSE
    // console.log(currentAnswerPicked);
    // console.log('PREV-ques->'+curQues);
   // console.log('PREV-question->'+curQuesResult[curQues]);
    // console.log('PREV-pik ans->'+pickedAnsResult[ansWer]);
    // console.log('PREV-pik indx->'+pickedIndexResult[indexPick]);

}


// const onAnswerClick = (ans, index) =>{

//     setAnswerIdx(index)
//     setAnswer(ans)
    
// }

// const onClickNext = () =>{
//  //answerIdx   
  
//   var curQues ='ques'+ (currentQuestion + 1); //QUESTION NO
//   var indexPick ='indexPick'+(currentQuestion+1); //PICKED INDEX
//   var indexPick2 ='indexPick'+(currentQuestion+2); //PICKED INDEX
//   var ansWer ='ans'+ (currentQuestion + 1); // PICKED ANSWER
  
   
  
//    const curQuesResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
//      return key.includes(curQues) //|| value.toString().includes(curQues);
     
//     }))
  
//    const pickedIndexResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
//      return key.includes(indexPick2) //|| value.toString().includes(indexPick2);
     
//     }))
//    const pickedAnsResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
//      return key.includes(ansWer) //|| value.toString().includes(ansWer);
     
//     }))
     
  
//     setAnswerIdx(pickedIndexResult[indexPick2]);
   
  
//    const CheckIndex = curQuesResult[curQues]; 
 

//    if(CheckIndex >= 0)
//     {

//            if(answer == null){
               
//                //console.log('Right-Right+Right +0');  
           
//            }
//            else
//            {
//                  if(answer == correctAnswer)
//                  { 
                       
//                        if(md5(pickedAnsResult[ansWer]) === correctAnswer)
//                        {
//                           // console.log('Right-Right +0');  
//                        }
//                        else
//                        {

//                          //console.log('Wrong-Right=+5'); 
//                              setResult((prev) => ({
//                                ...prev,
//                                score:prev.score + Number(score),
//                                correctAnswers:prev.correctAnswers + 1,  
//                                wrongAnswers:prev.wrongAnswers - 1, 
//                              }));                              
//                        }

//                  }
//                  else
//                  {      


//                        if(md5(pickedAnsResult[ansWer]) === correctAnswer)
//                        {
//                           // console.log('Right-Wrong - 5');
//                            setResult((prev) => ({
//                              ...prev,
//                              score:prev.score - Number(score),
//                              correctAnswers:prev.correctAnswers - 1,  
//                              wrongAnswers:prev.wrongAnswers + 1, 
//                            }));  

//                        }
//                        else
//                        {
//                              //console.log('Wrong-Wrong 0');
                             
//                        }

//                  } 
//            }


//     }
//     else
//     {
      



//               if(md5(answer) === correctAnswer)
//               {
//                     //console.log('First Right  +5'); 
//                     setResult((prev) => ({
//                       ...prev,
//                       score:prev.score + Number(score),
//                       correctAnswers:prev.correctAnswers + 1,  
//                     }));
//               }
//               else
//               {   
//                 //console.log('First Wrong 0');

//                     setResult((prev) => ({
//                       ...prev,  
//                       wrongAnswers:prev.wrongAnswers + 1, 
//                     }));

//               } 
//     }


//        setCurrentAnswerPicked((prevAns) => 
//          ({...prevAns, [curQues]: currentQuestion + 1,  [ansWer]: answer, [indexPick]:answerIdx})
//           )

 

      
 
//         setAnswer(null);

//         if (currentQuestion !== questions.length - 1 ){

//           setCurrentQuestion((prev) => prev + 1)

//         }
//         else
//         {
          
//           if(CBTPage == 'MidTermCBT' || CBTPage == 'Examination')
//             {

//             UpdateResultScore(); 
 

//           }else if(CBTPage == 'GeneralCBT'){

//           setCurrentQuestion(0);

//           setShowResult(true);

//           }



//         }

// };

// const onClickPrevious = () =>{


//   setCurrentQuestion((prev) => prev - 1); 
//   var indexPick ='indexPick'+currentQuestion; //PICKED INDEX 

  
  
 
//     const pickedIndexResult=  Object.fromEntries(Object.entries(currentAnswerPicked).filter(([key, value])=>{
//       return key.includes(indexPick) //|| value.toString().includes(indexPick);
      
//      }))
  

//      setSavedPicked(pickedIndexResult[indexPick]);

//      setAnswerIdx(pickedIndexResult[indexPick]);

 
 
 
// };

const UpdateResultScore =() => {
  
  setActivateLoader(true);
  setIsLoading2(true);

  

  var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
  fetch(BaseAction+"UpdateStudentResult&student_code="+studentID+"&school_code="+schoolCode+"&resultType="+CBTPage+"&subject_id="+SubjecdID+"&result_score="+result.score+"&sub_caption="+pickedSubject,
      {
      method:'GET',
      header:headers
      })
            
            .then((response) => response.json())
            .then((responseJson) =>
            {  
                   
                //  console.log(responseJson[0].success) updated
                 if(responseJson[0].success == 'success' || responseJson[0].success == 'updated'){
                  
                  setShowResult(true);
                  setReUpdateScore(true);

                 } 
                 else {
                  setShowResult(true);
                  setReUpdateScore(false);
                 }

                  setActivateLoader(false);
                  setIsLoading2(false);

          })
          .catch((error) => { 

                  setActivateLoader(true);
                  setIsLoading2(false);
                  setMessage('Network error. Please check your internet data and try again')

          });
 
   
  };






const onTryAgain = () => {
  setSeconds(59);
  setMinutes(2);
  setSeconds2(0);
  setMinutes2(0);
  setResult(resultInitialState);
  setCurrentQuestion((prev) => prev);
  setShowResult(false);
  setAnswerIdx(null);
  setCurrentAnswerPicked({});  
}


var timer;

 useEffect(() => {

     if(seconds == 0 && minutes == 0)
     {

        setSeconds(0);
        setMinutes(0);
        
              if(CBTPage == 'MidTermCBT'){

                UpdateResultScore(); 

              }else if(CBTPage == 'Examination'){

                UpdateResultScore(); 

              }else if(CBTPage == 'GeneralCBT'){

              setCurrentQuestion(0);

              setShowResult(true);

               }
         
     }else
     {

        if(!showResult){
          
      
          timer = setInterval(() => {

              setSeconds(seconds - 1 ); 
              if(seconds === 0){
                  setMinutes(minutes - 1);
                  setSeconds(59);
              }

  

            setSeconds2(seconds2 + 1 ); 
            if(seconds2 === 59){
                setMinutes2(minutes2 + 1);
                setSeconds2(0);
               
            } 
            setpickTime(''+minutes2+':'+seconds2+'')


            
            if(CBTPage == 'MidTermCBT'){

              localStorage.setItem('test_time', minutes); 

            }else if(CBTPage == 'Examination'){

              localStorage.setItem('exam_time', minutes); 

            }else if(CBTPage == 'GeneralCBT'){

              localStorage.setItem('test_time', minutes); 

             }




        },1000)

        }
     }


  
     return()=> clearInterval(timer);
 

},[seconds]);

const handleOnKeyDown = (e) => {
  if (['Enter', 'ArrowRight', 'Tab'].includes(e.key)) {
    // select item
    e.preventDefault();
  } else if (e.key === 'ArrowUp') {
    // go to top item
    e.preventDefault();
  } else if (e.key === 'ArrowDown') {
    // go to bottom item
    e.preventDefault();
  } else if (e.key === 'Escape') {
    // escape
    e.preventDefault();
  }
};

 



function handleEsc(e) {
  if (e.keyCode === 27) {
    e.preventDefault();
  }
}

useEffect(() => {
  window.addEventListener("keydown", handleEsc);
  return () => {
    window.removeEventListener("keydown", handleEsc);
  };
}, []);




const search = useLocation().search;

  const LoginOptionUpdate =() => { 
    
    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value); 
    setLoginData(data); 
 
  };

 
  useLayoutEffect(() => {
    

    const online_stu_id  = new URLSearchParams(search).get('online_stu_id'); 
    const student_teacher= new URLSearchParams(search).get('student_teacher'); 
    const school_code    = new URLSearchParams(search).get('school_code'); 
    const student_class  = new URLSearchParams(search).get('student_class'); 
    const student_name   = new URLSearchParams(search).get('student_name'); 
    const photo          = new URLSearchParams(search).get('photo'); 
    
    setStudentTeacher(student_teacher);
    setSchoolCode(school_code);
    setStudentID(online_stu_id);
    setStudentClass(student_class);
    setStudentName(student_name);
    setStudentPhoto(photo);


    const stData  = localStorage.getItem('subject');  
    setPickedSubject(stData);

    LoginOptionUpdate();
 
           
  },[]); 

 
    const endCBTHanduler = () => {
            endCBT();
            navigate('/');
   
      }
    const refreshPage = () => {
         
            window.location.reload();
   
      }


      const  ReLoadHanduler =() =>{
        setActivateLoader(false);  
        setIsLoading2(false);
       
        
      }  




            if(activateLoader)
            {
            
            
                    if(isLoading2)
                    {
            
                        return(
                            <div> 
                            <Loading4 Switch={1} BgColor={loginData.school_bgcolor}  MessgNote={"Loading..."} />
                            </div>
                        )
                        
                    }           
                    else  
                    {      
                
                            return( 
                            <div> 
                            <Loading4 Switch={2} BgColor={loginData.school_bgcolor} MessgNote={message} ReLoadHanduler={ReLoadHanduler}  RefreshPage={refreshPage}  />
                            </div> 
                            )       
                    
                    }
                        
            
            
        }
        else
        {
        
        return (  
          <div onLoad={handle.enter} >
       
            <div className="Container1"   style={{backgroundColor:loginData.school_bgcolor,color:loginData.text_code}}> 
            
              <Container>  
                <Row>
                      <Col xs={2}  >
                      <img src={imageURL+loginData.school_code+'/'+loginData.school_logo} style={{marginTop:10,  }} className="imgLogoCol"/>
                      </Col>
        
                      <Col  xs={8} style={{textAlign:'center',paddingTop:20}}>  
                      <div className="colTextOne"    style={{textAlign:'center'}}>{loginData.school_name}</div>
                      <div className="colTextTwo"    style={{textAlign:'center'}}>{loginData.school_address}</div>
                      <div className="colTextThree"  style={{textAlign:'center'}}>{loginData.school_motor}</div>
                      </Col>

                      <Col xs={2}  >
                      <img src={imageURL+loginData.school_code+'/'+studentPhoto} className="imgLogoCol"/> 
                       
                      </Col>


                  </Row>
                </Container>
                
            </div>         
                            
            <p   style={{color:'black',textTransform:'uppercase',textAlign:'center'}} className="colTextTwo">  {studentName} (Class: {studentClass})</p>   
            

            <div className="quiz-container" style={{textAlign:'center',paddingBottom:200}}>


            <div style={{color:'black',fontSize:20,textTransform:'uppercase',textDecorationLine: "underline"}}>CBT {pickedSubject} </div>
              { !showResult ? 
                 <div>
                 <Container>  
                 <Row>
                         <Col xs={6}> 
                              <span  className="active-question-no">{currentQuestion + 1}</span>
                              <span  className="total-question">/{questions.length} Questions</span>
                          </Col>
                          <Col xs={6}>
                              <p style={{fontSize:30,color:'red'}}>
                              <span style={{fontSize:14,color:'#777777'}}>CBT Timer: </span> 
                              {minutes<10?'0'+minutes:minutes}:{seconds<10?'0'+seconds:seconds}
                              </p>
                          </Col>
                     
                  </Row>
                  </Container>        

                    <div style={{textAlign:'left'}}>

                       {question_img == 'null'?null:  <img src={question_img} style={{marginTop:10,width:300}}   />  }

                        
                    <h2 style={{marginTop:20,marginBottom:20,fontWeight:500, }} className="colTextTwo" >{question}</h2>

                    </div>

                    
                    
                    <div  style={{textAlign:'left',}}> 
                        <ul> 
                            { 
                              
                              choices.map((answer, index) => (
                              
                              
                              <li onClick={() => onAnswerClick(answer, index)}  key={answer}
                                className={answerIdx === index? 'selected-answer1':'selected-answer2'}
                                > 
                                {answer}
                              </li>
                              
                              
                              )) }
                        </ul> 

                        <div>

                        
                        <Row>  
                            <Col xs={6}  className="footer1">
                                {currentQuestion > 0 ?
                                  
                                  <button onClick={()=>onClickPrevious(currentQuestion)} style={{backgroundColor:loginData.school_bgcolor}} >
                                    Previous
                                  </button>
                                
                                  : 
                                  null
                                } 
                            </Col>

                           <Col xs={6}  className="footer2"> 
                              <button onClick={onClickNext} disabled={answerIdx == null}>
                                {currentQuestion === questions.length -1 ? "Finish": "Next"}
                              </button>
                          </Col>

                          </Row>
                        
                        
                        
                        </div>
                    </div>
                </div>                   
                  
                  : 

                  CBTPage == 'GeneralCBT'?

                                  
                                  <div className="result">
                                      <h3 style={{textDecorationLine: "underline",textTransform:'uppercase'}}> {CBTPage} RESULT</h3>

                                      <p>
                                      Total Questions: <span  style={{fontSize:25}} >{questions.length}</span>
                                      </p>
                                      <p>
                                      Total Score: <span style={{fontSize:25}}>{result.score}</span>
                                      </p>
                                      <p>
                                      Correct Answers: <span style={{fontSize:25}}>{result.correctAnswers}</span>
                                      </p>
                                      <p>
                                      Wrong Answers: <span style={{fontSize:25}}>{result.wrongAnswers}</span>
                                      </p>
                                      <p>
                                      Completed Time: <span style={{fontSize:25}}>{pickTime}s</span>
                                    
                                      </p>

                                      <div style={{display:'flex',width:'100%'}}>
                                      <button className="selected-answer2" onClick={()=>endCBTHanduler()}  style={{width:'30%'}}>
                                      Home
                                      </button>
                                      <button className="selected-answer1" onClick={onTryAgain}  style={{width:'30%'}}>
                                        Try Again
                                      </button>
                                      <button className="selected-answer1" onClick={()=>FetchGeneralCBTNextQuestion()}  style={{width:'30%'}}>
                                      Next
                                      </button>
                                    </div>
                                </div>

                

                      :



                      <div className="result">
                      <h3 style={{textDecorationLine: "underline",textTransform:'uppercase'}}> {CBTPage} RESULT</h3>

                      <p>
                      Total Questions: <span  style={{fontSize:25}} >{questions.length}</span>
                      </p>
                      <p>
                      Total Score: <span style={{fontSize:25}}>{result.score}</span>
                      </p>
                      <p>
                      Correct Answers: <span style={{fontSize:25}}>{result.correctAnswers}</span>
                      </p>
                      <p>
                      Wrong Answers: <span style={{fontSize:25}}>{result.wrongAnswers}</span>
                      </p>
                      <p>
                      Completed Time: <span style={{fontSize:25}}>{pickTime}s</span>
                    
                      </p>

                      <div style={{width:'100%'}}>
              
                      <button className="selected-answer1" onClick={()=>UpdateResultScore()}  style={{width:'50%',backgroundColor:'green'}}>
                      Continue
                      </button>
                    </div>
                      </div>
              }

            </div>


         
            </div>
        );
            
        
        }
}
 
export default GeneralCBTPage
//     <FullScreen handle={handle} onload={handleOnKeyDown}>
//     </FullScreen>